import React, { FC, memo } from 'react';
import {
  AxisConfig,
  BarPlot,
  ChartsXAxis,
  ChartsXAxisProps,
  ChartsYAxis,
  ResponsiveChartContainer,
  ChartsTextStyle,
} from '@mui/x-charts';
import { ChartsGrid, chartsGridClasses } from '@mui/x-charts/ChartsGrid';
import { ChartsOverlay } from '@mui/x-charts/ChartsOverlay';
import OrbyColorPalette from '../colors/ColorPalette';
import { Box } from '@mui/material';
import { getFontSize } from '../typography/typography-utils';
import { DateOptionValues } from '../../utils/constants';

export interface OrbyBarChartProps {
  data: (number | null)[];
  xAxisLabels?: string[];
  categoryGapRatio?: number;
  noDataLabel?: string;
  barColor?: string;
  timeRange?: DateOptionValues;
  currentDate?: Date;
  height?: string;
  width?: string;
  hoverColor?: string;
  gridLineColor?: string;
  axisLabelStyle?: React.CSSProperties;
  margin?: { left?: number; right?: number; bottom?: number; top?: number };
  showGrid?: boolean;
  borderRadius?: number;
  formatXAxisLabel?: (value: string) => string;
  tooltip?: React.ReactNode;
}

const defaultAxisLabelStyle: ChartsTextStyle = {
  fontSize: getFontSize('xs'),
  color: OrbyColorPalette['grey-500'],
  fill: OrbyColorPalette['grey-500'],
  lineHeight: '18.45px',
};

const OrbyBarChart: FC<OrbyBarChartProps> = ({
  data = [],
  timeRange = DateOptionValues.LAST_WEEK,
  categoryGapRatio = 0.7,
  xAxisLabels = [],
  noDataLabel = 'No data available',
  barColor = OrbyColorPalette['purple-700'],
  currentDate = new Date(),
  height = '250px',
  width = '450px',
  hoverColor = OrbyColorPalette['blue-700'],
  gridLineColor = OrbyColorPalette['grey-200'],
  axisLabelStyle = defaultAxisLabelStyle,
  margin = { left: 45, right: 10, bottom: 33 },
  showGrid = true,
  borderRadius = 7,
  formatXAxisLabel,
  tooltip,
}) => {
  return (
    <Box
      sx={{
        width: { width },
        height: { height },
        flex: 1,
      }}
    >
      <ResponsiveChartContainer
        resolveSizeBeforeRender={true}
        series={[
          {
            type: 'bar',
            data: data ? data : [],
          },
        ]}
        sx={{
          '& .MuiResponsiveChart-container': {
            justifyContent: 'start',
          },
          [`& .${chartsGridClasses.line}`]: {
            strokeDasharray: '5 3',
            strokeWidth: 1.5,
          },
          [`& .MuiBarElement-root:hover`]: {
            fill: hoverColor,
          },
        }}
        colors={[barColor]}
        margin={margin}
        xAxis={[
          {
            data: xAxisLabels,
            scaleType: 'band',
            disableLine: true,
            categoryGapRatio: categoryGapRatio,
            valueFormatter: formatXAxisLabel,
            tickLabelInterval: (value: string, index: number) => {
              // Show labels every 5th tick for long ranges, otherwise show all labels
              if (data?.length > 10) {
                return index % 5 === 0;
              }
              return true;
            },
          } as AxisConfig<'band', string, ChartsXAxisProps>,
        ]}
      >
        {showGrid && (
          <ChartsGrid
            horizontal={true}
            classes={{
              line: 'no-line-grid',
            }}
          />
        )}

        <ChartsOverlay
          slotProps={{
            noDataOverlay: {
              message: noDataLabel,
            },
          }}
        />

        <ChartsXAxis
          disableTicks={true}
          disableLine={false}
          tickLabelStyle={axisLabelStyle as ChartsTextStyle}
          sx={{
            line: {
              stroke: `${gridLineColor} !important`,
            },
          }}
          slotProps={{
            axisLine: {
              x1: 55,
              x2: 390,
            },
          }}
        />

        <ChartsYAxis
          disableTicks={true}
          disableLine={true}
          tickLabelStyle={axisLabelStyle as ChartsTextStyle}
        />

        {tooltip}

        <BarPlot
          slotProps={{
            bar: {
              opacity: 1,
            },
          }}
          borderRadius={borderRadius}
        />
      </ResponsiveChartContainer>
    </Box>
  );
};

export default memo(OrbyBarChart);
