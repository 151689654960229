import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import { FewShotTunableConfig } from 'protos/common/hyperparameter';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const FewShotStep: React.FC<{
  isViewOnly: boolean;
  value: FewShotTunableConfig;
  setValue: (newFewShotTunableConfig: FewShotTunableConfig) => void;
}> = ({ isViewOnly, value, setValue }) => {
  return (
    <Box width={'70%'} marginBottom={'15px'} marginLeft={'15px'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Temperature
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={value?.temperature ?? ''}
              onChange={(e) => {
                let temperature: number | undefined = parseFloat(
                  e.target.value,
                );
                if (isNaN(temperature)) {
                  temperature = undefined;
                }
                setValue({
                  ...value,
                  temperature: temperature?.toString() ?? '',
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Max Chunk Size
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={value?.maxChunkSize?.toString() ?? ''}
              onChange={(e) => {
                let maxChunkSize: number | undefined = parseInt(e.target.value);
                if (isNaN(maxChunkSize)) {
                  maxChunkSize = undefined;
                }
                setValue({
                  ...value,
                  maxChunkSize: maxChunkSize,
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Top P
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={value?.nucleusSamplingParameters?.topP ?? ''}
              onChange={(e) => {
                let topP: number | undefined = parseFloat(e.target.value);
                if (isNaN(topP)) {
                  topP = undefined;
                }
                setValue({
                  ...value,
                  nucleusSamplingParameters: {
                    ...value.nucleusSamplingParameters,
                    topP: topP?.toString() ?? '',
                  },
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Top K
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={value?.nucleusSamplingParameters?.topK?.toString() ?? ''}
              onChange={(e) => {
                let topK: number | undefined = parseInt(e.target.value);
                if (isNaN(topK)) {
                  topK = undefined;
                }
                setValue({
                  ...value,
                  nucleusSamplingParameters: {
                    ...value.nucleusSamplingParameters,
                    topK: topK,
                  },
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Candidate Count
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={
                value?.nucleusSamplingParameters?.candidateCount?.toString() ??
                ''
              }
              onChange={(e) => {
                let candidateCount: number | undefined = parseInt(
                  e.target.value,
                );
                if (isNaN(candidateCount)) {
                  candidateCount = undefined;
                }
                setValue({
                  ...value,
                  nucleusSamplingParameters: {
                    ...value.nucleusSamplingParameters,
                    candidateCount: candidateCount,
                  },
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Pipeline Type
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.pipelineType ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  pipelineType: e.target.value,
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Extraction Model Name
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.modelInfo?.extractionModelSpec?.modelName ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  modelInfo: {
                    ...value.modelInfo,
                    extractionModelSpec: {
                      modelName: e.target.value,
                    },
                  },
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Transcription Model Name
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.modelInfo?.transcriptionModelSpec?.modelName ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  modelInfo: {
                    ...value.modelInfo,
                    transcriptionModelSpec: {
                      modelName: e.target.value,
                    },
                  },
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Remove Entities without BBox
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <Checkbox
              checked={value?.removeEntitiesWithoutBbox ?? false}
              disabled={isViewOnly}
              size={'small'}
              onChange={(e) =>
                setValue({
                  ...value,
                  removeEntitiesWithoutBbox: e.target.checked,
                })
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Disable Rolling LLM Token Output
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <Checkbox
              checked={value?.disableRollingLlmTokenOutput ?? false}
              disabled={isViewOnly}
              size={'small'}
              onChange={(e) =>
                setValue({
                  ...value,
                  disableRollingLlmTokenOutput: e.target.checked,
                })
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Disable REM Fallback
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <Checkbox
              checked={value?.disableRemFallback ?? false}
              disabled={isViewOnly}
              size={'small'}
              onChange={(e) =>
                setValue({
                  ...value,
                  disableRemFallback: e.target.checked,
                })
              }
            />
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Max Answer Rolls
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                type: 'number',
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
              value={value?.maxAnswerRolls?.toString() ?? ''}
              onChange={(e) => {
                let maxAnswerRolls: number | undefined = parseInt(
                  e.target.value,
                );
                if (isNaN(maxAnswerRolls)) {
                  maxAnswerRolls = undefined;
                }
                setValue({
                  ...value,
                  maxAnswerRolls: maxAnswerRolls,
                });
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Extra Rules
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.extraRules ?? ''}
              disabled={isViewOnly}
              size={'small'}
              multiline
              rows={4}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  extraRules: e.target.value,
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        {/* child entity calibration */}
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Child Entity Calibration
          </CustomTypography>
          <Box sx={{ border: '1px solid #DDDBDA', p: 2, borderRadius: '4px' }}>
            {value?.childEntityCalibration?.map((calibration, index) => (
              <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <TextField
                  variant='outlined'
                  disabled={isViewOnly}
                  size='small'
                  type='number'
                  label='Calibrated'
                  value={calibration.calibratedConfidence ?? ''}
                  onChange={(e) => {
                    const newCalibrations = [
                      ...(value?.childEntityCalibration || []),
                    ];
                    newCalibrations[index] = {
                      ...newCalibrations[index],
                      calibratedConfidence: parseFloat(e.target.value) || 0,
                    };
                    setValue({
                      ...value,
                      childEntityCalibration: newCalibrations,
                    });
                  }}
                  sx={{ width: '45%' }}
                />
                <TextField
                  variant='outlined'
                  disabled={isViewOnly}
                  size='small'
                  type='number'
                  label='Uncalibrated'
                  value={calibration.uncalibratedConfidence ?? ''}
                  onChange={(e) => {
                    const newCalibrations = [
                      ...(value?.childEntityCalibration || []),
                    ];
                    newCalibrations[index] = {
                      ...newCalibrations[index],
                      uncalibratedConfidence: parseFloat(e.target.value) || 0,
                    };
                    setValue({
                      ...value,
                      childEntityCalibration: newCalibrations,
                    });
                  }}
                  sx={{ width: '45%' }}
                />
                <Tooltip
                  title={isViewOnly ? 'Insufficient Permissions' : 'Remove'}
                >
                  <span>
                    <IconButton
                      disabled={isViewOnly}
                      size='small'
                      onClick={() => {
                        const newCalibrations = [
                          ...(value?.childEntityCalibration || []),
                        ];
                        newCalibrations.splice(index, 1);
                        setValue({
                          ...value,
                          childEntityCalibration: newCalibrations,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            ))}
            <Button
              disabled={isViewOnly}
              variant='outlined'
              size='small'
              onClick={() => {
                setValue({
                  ...value,
                  childEntityCalibration: [
                    ...(value?.childEntityCalibration || []),
                    { calibratedConfidence: 0, uncalibratedConfidence: 0 },
                  ],
                });
              }}
              fullWidth
            >
              Add Child Entity Calibration
            </Button>
          </Box>
        </Grid>
        {/* simple entity calibration */}
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Simple Entity Calibration
          </CustomTypography>
          <Box sx={{ border: '1px solid #DDDBDA', p: 2, borderRadius: '4px' }}>
            {value?.simpleEntityCalibration?.map((calibration, index) => (
              <Box key={index} sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <TextField
                  variant='outlined'
                  disabled={isViewOnly}
                  size='small'
                  type='number'
                  label='Calibrated'
                  value={calibration.calibratedConfidence ?? ''}
                  onChange={(e) => {
                    const newCalibrations = [
                      ...(value?.simpleEntityCalibration || []),
                    ];
                    newCalibrations[index] = {
                      ...newCalibrations[index],
                      calibratedConfidence: parseFloat(e.target.value) || 0,
                    };
                    setValue({
                      ...value,
                      simpleEntityCalibration: newCalibrations,
                    });
                  }}
                  sx={{ width: '45%' }}
                />
                <TextField
                  variant='outlined'
                  disabled={isViewOnly}
                  size='small'
                  type='number'
                  label='Uncalibrated'
                  value={calibration.uncalibratedConfidence ?? ''}
                  onChange={(e) => {
                    const newCalibrations = [
                      ...(value?.simpleEntityCalibration || []),
                    ];
                    newCalibrations[index] = {
                      ...newCalibrations[index],
                      uncalibratedConfidence: parseFloat(e.target.value) || 0,
                    };
                    setValue({
                      ...value,
                      simpleEntityCalibration: newCalibrations,
                    });
                  }}
                  sx={{ width: '45%' }}
                />
                <Tooltip
                  title={isViewOnly ? 'Insufficient Permissions' : 'Remove'}
                >
                  <span>
                    <IconButton
                      disabled={isViewOnly}
                      size='small'
                      onClick={() => {
                        const newCalibrations = [
                          ...(value?.simpleEntityCalibration || []),
                        ];
                        newCalibrations.splice(index, 1);
                        setValue({
                          ...value,
                          simpleEntityCalibration: newCalibrations,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            ))}
            <Button
              disabled={isViewOnly}
              variant='outlined'
              size='small'
              onClick={() => {
                setValue({
                  ...value,
                  simpleEntityCalibration: [
                    ...(value?.simpleEntityCalibration || []),
                    { calibratedConfidence: 0, uncalibratedConfidence: 0 },
                  ],
                });
              }}
              fullWidth
            >
              Add Simple Entity Calibration
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FewShotStep;
