import {
  AccountBalanceWallet,
  CorporateFare,
  DataUsage,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dialog,
  Fab,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SlideUpTransition from '../../../components/core/SlideUpTransition';
import {
  CurrentBreadcrumb,
  NonCurrentBreadcrumb,
} from '../../../components/core/StyledBreadcrumb';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PERMISSION_HAS_BILLING_ACCESS,
  ROUTE_NAME_ORGANIZATIONS,
} from '../../../utils/constants';
import { generateBillingReportHtmlTemplate } from '../../../utils/generateBillingReportHtmlTemplate';
import BillingReportForm from './components/BillingReportForm';
import WorkflowsList from './components/WorkflowsList';
import { toastService } from '../../../services/ToastService';

const OrganizationDetails: React.FC = observer(() => {
  const { organizationId } = useParams();
  const store = useStore();
  const {
    organizationsList,
    generateBillingReport,
    generateUsageReport,
    billingReportForOrganization,
    resetBillingReport,
    resetUsageReport,
    billingReportError,
    usageReportError,
  } = store.internalAppStore;
  const { user } = store.authStore;
  const organization = organizationsList?.find(
    (org) => org.name === `organizations/${organizationId}`,
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isBillingReportFormOpen, setIsBillingReportFormOpen] = useState(false);
  const [isUsageReportFormOpen, setIsUsageReportFormOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [discount, setDiscount] = useState(0);

  const handleBillingReportFormOpen = () => {
    setIsBillingReportFormOpen(true);
  };

  const handleBillingReportFormClose = () => {
    setIsBillingReportFormOpen(false);
  };

  const handleUsageReportFormOpen = () => {
    setIsUsageReportFormOpen(true);
  };

  const handleUsageReportFormClose = () => {
    setIsUsageReportFormOpen(false);
  };

  useEffect(() => {
    if (billingReportForOrganization) {
      const isGoogleOrg =
        billingReportForOrganization.organizationDisplayName
          ?.toLowerCase()
          .includes('google') || false;
      const htmlTemplate = generateBillingReportHtmlTemplate(
        billingReportForOrganization,
        discount,
        // shouldSkipConnectorSourceTask is true if the organization is google
        isGoogleOrg,
      );
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(htmlTemplate);
        newWindow.document.close();
      }
      resetBillingReport();
    }
  }, [billingReportForOrganization]);

  useEffect(() => {
    if (billingReportError) {
      toastService.showError(
        `Failed to generate billing report: ${billingReportError}`,
      );
      resetBillingReport();
    }
  }, [billingReportError]);

  useEffect(() => {
    if (usageReportError) {
      toastService.showError(
        `Failed to generate usage report: ${usageReportError}`,
      );
      resetUsageReport();
    }
  }, [usageReportError]);

  const downloadBillingReport = () => {
    toastService.showInfo(
      'Generating billing report for the organization. Please wait',
    );
    generateBillingReport({
      organizationName: `organizations/${organizationId}`,
      startDate: new Date(dateRange.startDate),
      endDate: new Date(dateRange.endDate),
    });
    handleBillingReportFormClose();
  };

  const downloadUsageReport = () => {
    toastService.showInfo(
      'Generating usage report for the organization. Please wait',
    );
    generateUsageReport({
      organizationName: `organizations/${organizationId}`,
      startDate: new Date(dateRange.startDate),
      endDate: new Date(dateRange.endDate),
    });
    handleUsageReportFormClose();
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={isBillingReportFormOpen}
        onClose={handleBillingReportFormOpen}
        TransitionComponent={SlideUpTransition}
      >
        <BillingReportForm
          handleBillingReportFormClose={handleBillingReportFormClose}
          dateRange={dateRange}
          setDateRange={setDateRange}
          discount={discount}
          setDiscount={setDiscount}
          downloadBillingReport={downloadBillingReport}
          showDiscountSlider={true}
        />
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={isUsageReportFormOpen}
        onClose={handleUsageReportFormClose}
        TransitionComponent={SlideUpTransition}
      >
        <BillingReportForm
          handleBillingReportFormClose={handleBillingReportFormClose}
          dateRange={dateRange}
          setDateRange={setDateRange}
          discount={discount}
          setDiscount={setDiscount}
          downloadUsageReport={downloadUsageReport}
          showDiscountSlider={false}
        />
      </Dialog>
      <Box
        sx={{
          margin: '16px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          <Breadcrumbs separator='>'>
            <NonCurrentBreadcrumb
              icon={<CorporateFare fontSize='small' />}
              label='Organizations'
              href={`/${ROUTE_NAME_ORGANIZATIONS}`}
            />
            <CurrentBreadcrumb
              label={organization?.displayName ?? organizationId!}
            />
          </Breadcrumbs>
          <Box>
            {user?.permittedActions?.includes(
              PERMISSION_HAS_BILLING_ACCESS,
            ) && (
              <Fab
                variant='extended'
                color='primary'
                onClick={handleBillingReportFormOpen}
              >
                <AccountBalanceWallet sx={{ marginRight: '4px' }} />
                Billing Report
              </Fab>
            )}
            {user?.permittedActions?.includes(
              PERMISSION_HAS_BILLING_ACCESS,
            ) && (
              <Fab
                variant='extended'
                color='primary'
                onClick={handleUsageReportFormOpen}
              >
                <DataUsage sx={{ marginRight: '4px' }} />
                Usage Report
              </Fab>
            )}
          </Box>
        </Stack>

        <WorkflowsList organizationId={organizationId!} />
      </Box>
    </>
  );
});

export default memo(OrganizationDetails);
