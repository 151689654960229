import { saveAs } from 'file-saver';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { Hyperparameter } from 'protos/common/hyperparameter';
import {
  CreateHyperparameterRequest,
  GenerateBillingReportRequest,
  GenerateBillingReportResponse,
  GetPredictionAnalysisRequest,
  GetUserPermissionsRequest,
  ListHyperparametersRequest,
  ListOrganizationsRequest,
  ListUsersRequest,
  ListWorkflowsForOrganizationRequest,
  UpdateUserRequest,
  UserWithPrivilageLevel,
} from 'protos/pb/orby_internal/orby_internal_service';
import { Organization } from 'protos/pb/v1alpha1/organization';
import {
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
} from 'protos/pb/v1alpha1/organization_service';
import {
  UpdateWorkflowRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import { orbyInternalService } from '../services/OrbyInternalAppService';
import { organizationsService } from '../services/OrganizationsService';
import { RootStore } from './store';

class OrbyInternalAppStore {
  rootStore: RootStore;

  @observable hyperparameterNameDisplayNameMap: { [name: string]: string } = {};

  // List organizations
  @observable loadingOrganizationsList = false;
  @observable organizationsListError?: Error;
  @observable organizationsList: Organization[] = [];
  @observable totalOrganizationsCount?: number;

  // Workflows for organization
  @observable loadingWorkflowsForOrganization = false;
  @observable workflowsForOrganizationError?: Error;
  @observable workflowsForOrganization: Workflow[] = [];
  @observable totalWorkflowsForOrganizationCount?: number;

  // Billing report
  @observable loadingBillingReport = false;
  @observable billingReportForOrganization?: GenerateBillingReportResponse;
  @observable billingReportError?: Error;

  // Usage report
  @observable loadingUsageReport = false;
  @observable usageReportError?: Error;

  // Prediction analysis
  @observable loadingPredictionAnalysis = false;
  @observable predictionAnalysisError?: Error;

  // Hyperparameters
  @observable loadingHyperparameters = false;
  @observable hyperparametersError?: Error;
  @observable hyperparameters: Hyperparameter[] = [];
  @observable totalHyperparametersCount?: number;

  // Hyperparameter Details
  @observable hyperparameterDetailError?: Error;
  @observable hyperparameterDetail?: Hyperparameter;
  @observable hyperparameterDetailLoading = false;

  // Users
  @observable users: UserWithPrivilageLevel[] = [];
  @observable usersError?: Error;
  @observable loadingUsers = false;
  @observable totalUsersCount?: number;
  @observable userPermissions: string[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  resetOrganizationsListError = () => {
    runInAction(() => {
      this.organizationsListError = undefined;
    });
  };

  resetWorkflowsForOrganizationError = () => {
    runInAction(() => {
      this.workflowsForOrganizationError = undefined;
    });
  };

  resetHyperparametersError = () => {
    runInAction(() => {
      this.hyperparametersError = undefined;
    });
  };

  resetUsersError = () => {
    runInAction(() => {
      this.usersError = undefined;
    });
  };

  resetPredictionAnalysisError = () => {
    runInAction(() => {
      this.predictionAnalysisError = undefined;
    });
  };

  getOrganizationsList = async (
    req: ListOrganizationsRequest,
    refresh: boolean,
  ) => {
    runInAction(() => {
      this.loadingOrganizationsList = true;
    });

    const { response, error } = await orbyInternalService.getOrganizations(req);
    if (error) {
      runInAction(() => {
        this.organizationsListError = error;
        this.loadingOrganizationsList = false;
      });
    } else {
      for (const org of response?.organizations ?? []) {
        if (
          org.hyperparameterResourceName &&
          org.hyperparameterResourceName in
            this.hyperparameterNameDisplayNameMap
        ) {
          continue;
        }
        const { resp, error } =
          await orbyInternalService.getHyperparameterDetails({
            hyperparameterName: org.hyperparameterResourceName,
          });
        if (error) {
          continue; /* Skip */
        }

        runInAction(() => {
          this.hyperparameterNameDisplayNameMap[
            org.hyperparameterResourceName!
          ] = resp?.hyperparameter?.displayName ?? '';
        });
      }
      runInAction(() => {
        if (refresh) {
          this.organizationsList = response?.organizations ?? [];
        } else {
          this.organizationsList.push(...(response?.organizations ?? []));
        }
        this.totalOrganizationsCount = response?.totalSize;
        this.loadingOrganizationsList = false;
      });
    }
  };

  getWorkflowsForOrganization = async (
    req: ListWorkflowsForOrganizationRequest,
    refresh: boolean,
  ) => {
    runInAction(() => {
      this.loadingWorkflowsForOrganization = true;
    });

    const { response, error } =
      await orbyInternalService.getWorkflowsForOrganization(req);

    if (error) {
      runInAction(() => {
        this.workflowsForOrganizationError = error;
        this.loadingWorkflowsForOrganization = false;
      });
    } else {
      for (const workflow of response?.workflows ?? []) {
        if (
          workflow.hyperparameterResourceName &&
          workflow.hyperparameterResourceName in
            this.hyperparameterNameDisplayNameMap
        ) {
          continue;
        }
        const { resp, error } =
          await orbyInternalService.getHyperparameterDetails({
            hyperparameterName: workflow.hyperparameterResourceName,
          });
        if (error) {
          continue; /* Skip */
        }

        runInAction(() => {
          this.hyperparameterNameDisplayNameMap[
            workflow.hyperparameterResourceName!
          ] = resp?.hyperparameter?.displayName ?? '';
        });
      }

      runInAction(() => {
        if (refresh) {
          this.workflowsForOrganization = response?.workflows ?? [];
        } else {
          this.workflowsForOrganization.push(...(response?.workflows ?? []));
        }
        this.totalWorkflowsForOrganizationCount = response?.totalSize;
        this.loadingWorkflowsForOrganization = false;
      });
    }
  };

  generateBillingReport = async (req: GenerateBillingReportRequest) => {
    runInAction(() => {
      this.loadingBillingReport = true;
      this.billingReportError = undefined;
      this.billingReportForOrganization = undefined;
    });
    const { response, error } =
      await orbyInternalService.generateBillingReport(req);
    if (error) {
      runInAction(() => {
        this.billingReportForOrganization = undefined;
        this.loadingBillingReport = false;
        this.billingReportError = error;
      });
      return;
    }

    runInAction(() => {
      this.billingReportForOrganization = response;
      this.loadingBillingReport = false;
    });
  };

  resetBillingReport = () => {
    runInAction(() => {
      this.loadingBillingReport = false;
      this.billingReportError = undefined;
      this.billingReportForOrganization = undefined;
    });
  };

  resetUsageReport = () => {
    runInAction(() => {
      this.loadingUsageReport = false;
      this.usageReportError = undefined;
    });
  };

  getPredictionAnalysis = async (req: GetPredictionAnalysisRequest) => {
    runInAction(() => {
      this.predictionAnalysisError = undefined;
      this.loadingPredictionAnalysis = true;
    });

    const { response, error } =
      await orbyInternalService.getPredictionAnalysis(req);
    if (error) {
      console.error('Error getting prediction analysis:', error);
      runInAction(() => {
        this.predictionAnalysisError = error;
        this.loadingPredictionAnalysis = false;
      });
      return;
    }

    let signedUrl = '';
    let fileName = '';
    for (const analysis of response?.analysis ?? []) {
      // Assuming the first analysis report is the one we want to download
      signedUrl = analysis.reportGcsUri!;
      fileName = `${analysis.workflowName!}.csv`;
      break;
    }

    const fileResp = await fetch(signedUrl);
    const fileData = await fileResp.blob();
    saveAs(fileData, fileName);

    runInAction(() => {
      this.loadingPredictionAnalysis = false;
    });
  };

  generateUsageReport = async (req: GenerateBillingReportRequest) => {
    runInAction(() => {
      this.usageReportError = undefined;
      this.loadingUsageReport = true;
    });

    const { response, error } =
      await orbyInternalService.generateBillingReport(req);
    if (error) {
      console.error('Error getting usage report:', error);
      runInAction(() => {
        this.usageReportError = error;
        this.loadingUsageReport = false;
      });
      return;
    }
    const fileName = `${response?.organizationDisplayName}.csv`;
    const totalPages =
      response?.workflowData?.reduce(
        (sum, workflow) => sum + (workflow.totalPages || 0),
        0,
      ) || 0;

    const csvRows = [
      ['Organization Name', response?.organizationDisplayName || ''],
      ['Time Window', `From ${response?.startDate} to ${response?.endDate}`],
      ['Total Tasks', `${response?.totalTasks || 0}`],
      ['Workflow count', `${response?.workflowData?.length || 0}`],
      ['Total pages', `${totalPages}`],
      ['', ''],
      ['', ''],
      ['', ''],
      ['Description', 'Page Count'],
      ...(response?.workflowData?.map((workflow) => [
        workflow.workflowDisplayName || '',
        `${workflow.totalPages || 0}`,
      ]) || []),
    ];

    // Convert rows to CSV string
    const csvContent = csvRows
      .map((row) => row.map((cell) => `"${cell}"`).join(','))
      .join('\n');

    // Create blob and use saveAs
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);

    runInAction(() => {
      this.loadingUsageReport = false;
    });
  };

  createOrganization = async (req: CreateOrganizationRequest) => {
    await organizationsService.createOrganization(req);

    runInAction(() => {
      this.getOrganizationsList(
        {
          pageSize: 10,
          pageNumber: 1,
        },
        true,
      );
    });
  };

  getHyperparametersList = async (
    req: ListHyperparametersRequest,
    refresh: boolean,
  ) => {
    runInAction(() => {
      this.loadingHyperparameters = true;
    });

    const { response, error } =
      await orbyInternalService.getHyperparameters(req);
    if (error) {
      runInAction(() => {
        this.hyperparametersError = error;
        this.loadingHyperparameters = false;
      });
    } else {
      runInAction(() => {
        if (refresh) {
          this.hyperparameters = response?.hyperparameters ?? [];
        } else {
          this.hyperparameters.push(...(response?.hyperparameters ?? []));
        }
        this.totalHyperparametersCount = response?.totalSize;
        this.loadingHyperparameters = false;
      });
    }
  };

  createHyperparameters = async (
    req: CreateHyperparameterRequest,
  ): Promise<Error | undefined> => {
    const { error } = await orbyInternalService.createHyperparameter(req);

    return error;
  };

  assignHyperparameterToOrg = async (
    orgName: string,
    hyperparameterName?: string,
  ): Promise<Error | undefined> => {
    const req: UpdateOrganizationRequest = {
      organization: {
        name: orgName,
        hyperparameterResourceName: hyperparameterName,
      },
      fieldMask: ['hyperparameter_resource_name'],
    };

    const { error } = await orbyInternalService.updateOrganization(req);
    return error;
  };

  assignHyperparameterToWorkflow = async (
    workflowName: string,
    orgResourceName: string,
    hyperparameterName?: string,
  ): Promise<Error | undefined> => {
    const req: UpdateWorkflowRequest = {
      workflow: {
        name: workflowName,
        hyperparameterResourceName: hyperparameterName,
        organizationResourceName: orgResourceName,
      },
      fieldMask: ['hyperparameter_resource_name'],
    };

    const { error } = await orbyInternalService.updateWorkflow(req);
    return error;
  };

  updateOrganization = async (
    org: Organization,
  ): Promise<Error | undefined> => {
    const req: UpdateOrganizationRequest = {
      organization: org,
      fieldMask: ['workflow_template_types'],
    };

    const { error } = await orbyInternalService.updateOrganization(req);
    return error;
  };

  getHyperparameterDetails = async (hyperparameterName: string) => {
    runInAction(() => {
      this.hyperparameterDetailLoading = true;
      this.hyperparameterDetailError = undefined;
      this.hyperparameterDetail = undefined;
    });

    const existingHyperparameter = this.hyperparameters.find(
      (h) => h.name === hyperparameterName,
    );

    if (existingHyperparameter) {
      runInAction(() => {
        this.hyperparameterDetail = existingHyperparameter;
        this.hyperparameterDetailLoading = false;
      });
      return;
    }

    const { resp, error } = await orbyInternalService.getHyperparameterDetails({
      hyperparameterName,
    });

    if (error) {
      runInAction(() => {
        this.hyperparameterDetailError = error;
        this.hyperparameterDetailLoading = false;
      });
      return;
    }

    runInAction(() => {
      this.hyperparameterDetail = resp?.hyperparameter;
      this.hyperparameterDetailLoading = false;
    });
  };

  resetHyperparameterDetail = () => {
    runInAction(() => {
      this.hyperparameterDetail = undefined;
      this.hyperparameterDetailError = undefined;
      this.hyperparameterDetailLoading = false;
    });
  };

  updateHyperparameter = async (
    hyperparameter: Hyperparameter,
  ): Promise<Error | undefined> => {
    const { error } = await orbyInternalService.updateHyperparameter({
      hyperparameter,
    });
    return error;
  };

  updateUser = async (req: UpdateUserRequest) => {
    const { error } = await orbyInternalService.updateUser(req);
    return error;
  };

  getUsersList = async (req: ListUsersRequest, refresh: boolean) => {
    runInAction(() => {
      this.loadingUsers = true;
      this.usersError = undefined;
    });

    const { response, error } = await orbyInternalService.getUsers(req);

    if (error) {
      runInAction(() => {
        this.usersError = error;
        this.loadingUsers = false;
      });
    } else {
      runInAction(() => {
        if (refresh) {
          this.users = response?.users ?? [];
        } else {
          this.users.push(...(response?.users ?? []));
        }
        this.totalUsersCount = response?.totalSize;
        this.loadingUsers = false;
      });
    }
  };

  getUserPermissions = async (req: GetUserPermissionsRequest) => {
    const { response, error } =
      await orbyInternalService.getUserPermissions(req);

    if (error) {
      runInAction(() => {
        this.userPermissions = [];
      });
    } else {
      runInAction(() => {
        this.userPermissions = response?.permittedActions ?? [];
      });
    }
  };
}

export default OrbyInternalAppStore;
